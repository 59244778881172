import { useState } from "react"
import Rating from "react-rating"

export const UpdateGameModal = ({userData, onClose, onUpdate}) => {
    const [userHours, setUserHours] = useState(userData.user_hours ? userData.user_hours : "")
    const [userRating, setUserRating] = useState(userData.user_rating ? userData.user_rating : undefined)
    const [userYear, setUserYear] = useState(userData.played_year ? userData.played_year : undefined)
    const [switchButtons,setSwitchButtons] = useState()
    const [errMessage,setErrMessage] = useState()

    const updateCat = (cat) => {
        userData.category = cat;
        onUpdate(userData)
    }

    const validateYear = (e) => {
        e.preventDefault()
        if(userYear >= 1900 && userYear <= new Date().getFullYear()) {
            setErrMessage()
            setUserYear(userYear)
            if(userHours !== "") userData.user_hours = userHours;
            if(userRating) userData.user_rating = userRating;
            userData.played_year = userYear;
            onUpdate(userData)
            setUserYear()
        }
        else
            setErrMessage("Year must be between 1900 and the current year")
    }

    const switchButtonsFunc = () => {
        switch(userData.category) {
            case 0:
                setSwitchButtons(<div style={{display:"flex",justifyContent:"space-evenly"}}>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(1)} title="Switch to 'Planning to Play'"><i className="fa fa-book" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(3)} title="Switch to 'On Hold'"><i className="fa fa-pause" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(2)} title="Switch to 'Completed'"><i className="fa fa-trophy" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(4)} title="Switch to 'Dropped'"><i className="fa fa-ban" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(5)} title="Switch to 'Watchlist'"><i className="fa fa-list-ul" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                </div>)
                break;
            case 1:
                setSwitchButtons(<div style={{display:"flex",justifyContent:"space-evenly"}}>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(0)} title="Switch to 'Currently Playing'"><i className="fa fa-gamepad" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(3)} title="Switch to 'On Hold'"><i className="fa fa-pause" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(2)} title="Switch to 'Completed'"><i className="fa fa-trophy" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(4)} title="Switch to 'Dropped'"><i className="fa fa-ban" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(5)} title="Switch to 'Watchlist'"><i className="fa fa-list-ul" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                </div>)
                break;
            case 2:
                setSwitchButtons(<div style={{display:"flex",justifyContent:"space-evenly"}}>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(0)} title="Switch to 'Currently Playing'"><i className="fa fa-gamepad" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(1)} title="Switch to 'Planning to Play'"><i className="fa fa-book" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(3)} title="Switch to 'On Hold'"><i className="fa fa-pause" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(4)} title="Switch to 'Dropped'"><i className="fa fa-ban" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(5)} title="Switch to 'Watchlist'"><i className="fa fa-list-ul" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                </div>)
                break;
            case 3:
                setSwitchButtons(<div style={{display:"flex",justifyContent:"space-evenly"}}>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(0)} title="Switch to 'Currently Playing'"><i className="fa fa-gamepad" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(1)} title="Switch to 'Planning to Play'"><i className="fa fa-book" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(2)} title="Switch to 'Completed'"><i className="fa fa-trophy" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(4)} title="Switch to 'Dropped'"><i className="fa fa-ban" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(5)} title="Switch to 'Watchlist'"><i className="fa fa-list-ul" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                </div>)
                break;
            case 4:
                setSwitchButtons(<div style={{display:"flex",justifyContent:"space-evenly"}}>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(0)} title="Switch to 'Currently Playing'"><i className="fa fa-gamepad" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(1)} title="Switch to 'Planning to Play'"><i className="fa fa-book" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(3)} title="Switch to 'On Hold'"><i className="fa fa-pause" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(2)} title="Switch to 'Completed'"><i className="fa fa-trophy" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(5)} title="Switch to 'Watchlist'"><i className="fa fa-list-ul" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                </div>)
                break;
            case 5:
                setSwitchButtons(<div style={{display:"flex",justifyContent:"space-evenly"}}>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(0)} title="Switch to 'Currently Playing'"><i className="fa fa-gamepad" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(1)} title="Switch to 'Planning to Play'"><i className="fa fa-book" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(3)} title="Switch to 'On Hold'"><i className="fa fa-pause" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(2)} title="Switch to 'Completed'"><i className="fa fa-trophy" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                    <button style={{fontSize:"24px"}} className="btn btn-sm" onClick={() => updateCat(4)} title="Switch to 'Dropped'"><i className="fa fa-ban" style={{color:"#0d6efd"}} aria-hidden="true" /></button>
                                </div>)
                break;
        }
    }

    !switchButtons ? switchButtonsFunc() : null

    return (
        <>
            <div id="updateEventModal">
                <form onSubmit={validateYear}>
                    <div className="boxTitle" style={{fontSize:"1vw",paddingBottom:"10px"}}>{userData.game.name}</div>
                    <div style={{width:"80px",height:"3px",backgroundColor:"red"}} />
                    <div style={{display:"grid",gridTemplateColumns:"auto",marginBottom:"10px"}}>
                        {switchButtons}
                        <div>
                            {errMessage && <i style={{color:"red"}}>{errMessage}</i>}
                            <div className="boxTitle" style={{fontSize:"16px"}}>
                                Year Played:
                                <span style={{marginLeft:"10px"}}><input
                                    type="number"
                                    placeholder="Year..."
                                    maxLength={4}
                                    value={userYear}
                                    onChange={(e) => {setUserYear(e.target.value)}}
                                    style={{width:"36%"}}
                                    required
                                />
                                </span>
                            </div>
                        </div>
                        <div>
                            <div className="boxTitle" style={{fontSize:"16px"}}>
                                Hours Played:
                                <span style={{marginLeft:"10px"}}><input
                                    type="number"
                                    placeholder="Hours..."
                                    value={userHours}
                                    onChange={(e) => {setUserHours(e.target.value)}}
                                    style={{width:"36%"}}
                                />
                                </span>
                            </div>
                        </div>
                        <div>
                            <div className="boxTitle" style={{fontSize:"16px"}}>Personal Rating: </div>
                            <Rating
                                stop={10}
                                fractions={2}
                                initialRating={userRating}
                                onChange={(value) => {setUserRating(value)}}
                                emptySymbol="fa fa-star-o fa-2x"
                                fullSymbol="fa fa-star fa-2x"
                                style={{color:"gold",fontSize:"11px"}}
                            />
                        </div>
                    </div>
                    <div style={{textAlign:"center"}}>
                        <button className="btn btn-primary" type="submit" id="saveButton">Update</button>
                    </div>
                </form>
                <button onClick={onClose} style={{position:"absolute",top:"10px",right:"10px",paddingTop:"0px",paddingBottom:"1px",paddingRight:"5px",paddingLeft:"5px"}} className="btn btn-danger"><i className="fa fa-close" /></button>
            </div>
            <div id="modalBackDrop"/>
        </>
    )
}

export default UpdateGameModal;