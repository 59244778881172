import React from 'react';

export const Day = ({ day, onClick }) => {
  const className = `day ${day.value === 'padding' ? 'padding' : ''} ${day.isCurrentDay ? 'currentDay' : ''}`;
  const threeEvents = !day.event ? '' : day.event.slice(0,3).map((e, index) => <div key={index} className='event'>{e.title}</div>);
  const eventCount = day.event === null ? '' : day.event.length;
  return (
    <div onClick={onClick} className={className}>
      {day.value === 'padding' ? '' : day.value}
      {day.event && eventCount<4 && eventCount>0 && <div className='event'>{threeEvents}</div>}
      {day.event && eventCount>=4 && <div className='event'>{eventCount} Events</div>}
    </div>
  );
};

export default Day;