import React from 'react';

export const DeleteEventModal = ({ onDeleteEvent, eventText, onClose }) => {
  return(
    <>
      <div id="deleteEventModal">
        <div className="boxTitle" style={{fontSize:"25px",textAlign:"center"}}>Are you sure you want to delete "{eventText}"?</div>
        <br />
        <div style={{textAlign:"center"}}><button className="btn btn-danger" onClick={()=>{onDeleteEvent(eventText)}} id="deleteButton">Delete</button></div>
        <button onClick={onClose} style={{position:"absolute",top:"10px",right:"10px",paddingTop:"0px",paddingBottom:"1px",paddingRight:"5px",paddingLeft:"5px"}} className="btn btn-danger"><i className="fa fa-close" /></button>
      </div>
      <div id="modalBackDrop" />
    </>
  );
};

export default DeleteEventModal;