import React, { useEffect, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Tab } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import { UserListGame } from '../UserList/UserListGame';

export const AwardData = ({userData, val, years, year, personalAwardTabIndex}) => {
    const awardTypes = [
        "Best Narrative",
        "Best Art Direction",
        "Best Audio Design",
        "Best Score and Music",
        "Best Game Direction",
        "Best Multiplayer",
        "Best Single Player",
        "Game of The Year",
        "Best Overall Games"
        ]
    const defaultAwardData = [{
        awardYear:"All",
        data : [
            {type:"Best Narrative", games:[]},
            {type:"Best Art Direction",games:[]},
            {type:"Best Audio Design",games:[]},
            {type:"Best Score and Music",games:[]},
            {type:"Best Game Direction",games:[]},
            {type:"Best Multiplayer",games:[]},
            {type:"Best Single Player",games:[]},
            {type:"Best Overall Games",games:[]}
        ]
    }]
    const [awardData,setAwardData] = useState(localStorage.getItem('awarddata') ? JSON.parse(localStorage.getItem('awarddata')) : defaultAwardData)
    const [awardTypeTabIndex,setAwardTypeTabIndex] = useState("0");
    const [showAllAwards,setShowAllAwards] = useState(false);
    const [awardSlotIndex,setAwardSlotIndex] = useState();
    const [selectedAwardTypeGames,setSelectedAwardTypeGames] = useState();

    useEffect(() => {
        localStorage.setItem('awarddata', JSON.stringify(awardData));
    }, [awardData]);

    useEffect(() => {
        let temp = [...awardData]
        let difference = years && years.filter((year)=> temp.findIndex((item)=>item.awardYear === year) === -1)
        if(difference && difference.length > 0) {
            difference.map((year)=> {
                    temp.push(
                        {   
                            awardYear:year,
                            data:[{type:"Best Narrative", games:[]},
                            {type:"Best Art Direction",games:[]},
                            {type:"Best Audio Design",games:[]},
                            {type:"Best Score and Music",games:[]},
                            {type:"Best Game Direction",games:[]},
                            {type:"Best Multiplayer",games:[]},
                            {type:"Best Single Player",games:[]},
                            {type:"Game of the Year",games:[]}]
                        }
                    )
            })
            setAwardData(temp)
        }
    }, [years]);

    useEffect(() => {
        setAwardTypeTabIndex("0")
    },[personalAwardTabIndex])

    useEffect(() => {
        setShowAllAwards(false)
    },[awardTypeTabIndex])

    useEffect(() => {
        if(awardData && Number(personalAwardTabIndex) > 0) {
            if(personalAwardTabIndex === "1")
                setSelectedAwardTypeGames(awardData.slice()[0].data[Number(awardTypeTabIndex)].games)
            else
                years && setSelectedAwardTypeGames(awardData.slice(1).find((item)=> item.awardYear === years[Number(personalAwardTabIndex)-2]) && awardData.slice(1).find((item)=> item.awardYear === Array.from(years)[Number(personalAwardTabIndex)-2]).data[Number(awardTypeTabIndex)].games)
        }
    }, [awardData,personalAwardTabIndex,awardTypeTabIndex]);

    const addToAwards = (game) => {
        const temp = [...awardData]
        if(personalAwardTabIndex === "1")
            temp[0].data[Number(awardTypeTabIndex)].games[Number(awardSlotIndex)] = {game:game}
        else if(years)
            temp.slice(1).find((item)=> item.awardYear === years[Number(personalAwardTabIndex)-2]).data[Number(awardTypeTabIndex)].games[Number(awardSlotIndex)] = {game:game}
        setAwardData(temp)
    }

    const delFromAwards = (game) => {
        const temp = [...awardData]
        if(personalAwardTabIndex === "1")
            temp[0].data[Number(awardTypeTabIndex)].games[selectedAwardTypeGames.findIndex(e => e && e.game.name === game.name)] = null
        else
            temp.slice(1).find((item)=> item.awardYear === years[Number(personalAwardTabIndex)-2]).data[Number(awardTypeTabIndex)].games[selectedAwardTypeGames.findIndex(e => e && e.game.name === game.name)] = null
        setAwardData(temp)
    }

    return (
    <TabPanel value={val} sx={{padding:"0px",maxWidth:"71vw"}}>
        <TabContext value={awardTypeTabIndex}>
            <TabList variant="scrollable" onChange={(e, tabIndex) => setAwardTypeTabIndex(tabIndex)} sx={{borderBottom:"1px solid #a760e9",".Mui-selected": {color:"white !important"}}} TabIndicatorProps={{style:{backgroundImage: "linear-gradient(270deg, #a760e9 0%, rgba(100, 180, 250) 50%, #a760e9 100%)"}}}>
                <Tab label={awardTypes[0]} value="0" sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />
                <Tab label={awardTypes[1]} value="1" sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />
                <Tab label={awardTypes[2]} value="2" sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />
                <Tab label={awardTypes[3]} value="3" sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />
                <Tab label={awardTypes[4]} value="4" sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />
                <Tab label={awardTypes[5]} value="5" sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />
                <Tab label={awardTypes[6]} value="6" sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />
                <Tab label={val === "1" ? awardTypes[8] : awardTypes[7]} value="7" sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />
            </TabList>
            <TabPanel value={awardTypeTabIndex} style={{padding:"10px",maxHeight:"45vh"}}>
                {showAllAwards ?
                    <button className="btn btn-secondary btn-sm" onClick={()=>setShowAllAwards(false)}>Hide All</button>
                :
                    <button className="btn btn-secondary btn-sm" onClick={()=>setShowAllAwards(true)}>Reveal All</button>
                }
                <div id="awardSection" style={{display:"flex",gap:"25px",height:"360px",overflow:"auto",transition:"0.5s"}} onMouseOver={()=>{if(document.getElementById("awardSection")) {document.getElementById("awardSection").style.height = "360px";document.getElementById("gameSection").style.maxHeight = "240px";}}}>
                    {[...Array(10)].map((item,index2)=> {
                        let sty = {width:"150px",height:"270px"};
                        switch(index2) {
                            case 7:
                            case 8:
                                sty.width="175px"
                                sty.height="300px"
                                break;
                            case 9:
                                sty.width="200px"
                                sty.height="330px"
                                break;
                        }
                        return(
                            <div key={index2}>
                                <a onClick={()=>setAwardSlotIndex(index2)}>
                                    {selectedAwardTypeGames && selectedAwardTypeGames[index2] ? 
                                        <div style={sty}>
                                            <UserListGame
                                                gameData={userData.find((item)=>item.game.name === selectedAwardTypeGames[index2].game.name)}
                                                userData={userData}
                                                awarded={true}
                                                awardGames={selectedAwardTypeGames}
                                                show={showAllAwards}
                                            />
                                        </div>
                                    :
                                        <div style={{width:sty.width,height:sty.height,display:"flex",justifyContent:"center",textAlign:"center",border:index2===awardSlotIndex ? "2px dashed rgba(100, 180, 250)" : "2px dashed #a760e9",borderRadius:"20px"}}>
                                            <div style={{margin:"auto",color:"#a760e9",textShadow:"1px rgba(100, 180, 250)"}}>
                                                <i className="fa fa-plus" aria-hidden="true" />
                                                <br/>
                                                Click To Add
                                            </div>
                                        </div>
                                    }
                                </a>
                            </div>
                        )
                    })}
                </div>
                <div id="gameSection" style={{display:"flex",flexWrap:"wrap",justifyContent:"space-evenly",overflowX:"hidden",paddingRight:"10px",maxHeight:"240px",overflowY:"scroll",transition:"0.5s"}} onMouseOver={()=>{if(document.getElementById("awardSection")) {document.getElementById("awardSection").style.height = "240px";document.getElementById("gameSection").style.maxHeight = "360px";}}}>
                    {userData && userData.slice().map((item,index3)=>{
                        const awardGameFilter = personalAwardTabIndex === "1" ? (item.user_hours !== "" && item.user_rating) : (item.user_hours !== "" && item.user_rating && (new Date(item.game.first_release_date*1000).getFullYear() === year || (item.played_year && Number(item.played_year) === year)))
                        if(awardGameFilter)
                            return(
                                    <div style={{maxWidth:"150px"}}>
                                        <UserListGame
                                            key={index3}
                                            gameData={item}
                                            userData={userData}
                                            awardGames={selectedAwardTypeGames}
                                            addToAwards={(e) => addToAwards(e)}
                                            delFromAwards={(e) => delFromAwards(e)}
                                        />
                                    </div>
                                )
                    })}
                </div>
            </TabPanel>
        </TabContext>
    </TabPanel>
  );
};

export default AwardData;