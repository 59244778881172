import { apiDefaultGrab } from "../Utils/apiDefaultGrab";

export const SearchFilters = ({currentFilters,addToSearch,removeFromSearch}) => {
    const {genreData,themeData,perspectiveData,modeData} = apiDefaultGrab()
    return (
        <>
            <div style={{display:"grid",rowGap:"10px"}}>
                <div className="boxTitle" style={{fontSize:"24px"}}>Platforms</div>
                <div style={{width:"80px",height:"3px",backgroundColor:"red"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("PC (Microsoft Windows)") ? addToSearch({search:6,searchName:"PC (Microsoft Windows)",type:"platforms"}) : removeFromSearch("PC (Microsoft Windows)")}} >PC (Microsoft Windows)</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Playstation 5") ? addToSearch({search:167,searchName:"Playstation 5",type:"platforms"}) : removeFromSearch("Playstation 5")}} >Playstation 5</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Xbox Series X|S") ? addToSearch({search:169,searchName:"Xbox Series X|S",type:"platforms"}) : removeFromSearch("Xbox Series X|S")}} >Xbox Series X|S</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Nintendo Switch") ? addToSearch({search:130,searchName:"Nintendo Switch",type:"platforms"}) : removeFromSearch("Nintendo Switch")}} >Nintendo Switch</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Playstation 4") ? addToSearch({search:48,searchName:"Playstation 4",type:"platforms"}) : removeFromSearch("Playstation 4")}} >Playstation 4</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Playstation 3") ? addToSearch({search:9,searchName:"Playstation 3",type:"platforms"}) : removeFromSearch("Playstation 3")}} >Playstation 3</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Xbox One") ? addToSearch({search:49,searchName:"Xbox One",type:"platforms"}) : removeFromSearch("Xbox One")}} >Xbox One</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Xbox 360") ? addToSearch({search:12,searchName:"Xbox 360",type:"platforms"}) : removeFromSearch("Xbox 360")}} >Xbox 360</div>
                </div>
                <div className="boxTitle" style={{fontSize:"24px"}}>Game Modes</div>
                <div style={{width:"80px",height:"3px",backgroundColor:"orange"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                    {modeData ? modeData.map((e,index) => <div key={index} className="sFilterName" onClick={()=>{!currentFilters.includes(e.name) ? addToSearch({search:e.id,searchName:e.name,type:"game_modes"}) : removeFromSearch(e.name)}}>{e.name}</div>) : null}
                </div>
                <div className="boxTitle" style={{fontSize:"24px"}}>Genres</div>
                <div style={{width:"80px",height:"3px",backgroundColor:"yellow"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px",overflowY:"auto",height:"200px"}}>
                    {genreData ? genreData.map((e,index) => <div key={index} className="sFilterName" onClick={()=>{!currentFilters.includes(e.name) ? addToSearch({search:e.id,searchName:e.name,type:"genres"}) : removeFromSearch(e.name)}}>{e.name}</div>) : null}
                </div>
                <div className="boxTitle" style={{fontSize:"24px"}}>Player Perspectives</div>
                <div style={{width:"80px",height:"3px",backgroundColor:"green"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                    {perspectiveData ? perspectiveData.map((e,index) => <div key={index} className="sFilterName" onClick={()=>{!currentFilters.includes(e.name) ? addToSearch({search:e.id,searchName:e.name,type:"player_perspectives"}) : removeFromSearch(e.name)}}>{e.name}</div>) : null}
                </div>
                <div className="boxTitle" style={{fontSize:"24px"}}>Themes</div>
                <div style={{width:"80px",height:"3px",backgroundColor:"blue"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                    {themeData ? themeData.map((e,index) => <div key={index} className="sFilterName" onClick={()=>{!currentFilters.includes(e.name) ? addToSearch({search:e.id,searchName:e.name,type:"themes"}) : removeFromSearch(e.name)}}>{e.name}</div>) : null}
                </div>
            </div>
        </>
    )
}

export default SearchFilters;