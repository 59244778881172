import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useLocation, useParams } from 'react-router-dom';
import { getSearchData } from "../Hooks/apiData";
import { useSearchResults } from '../Hooks/useSearchResults';
import { SearchFilters } from '../Components/SearchFilters';
import axios from 'axios';

export const SearchResults = ({ userData, onUserListDel, onUserListSave, events, onEventSave, onEventDel}) => {
    const {search} = useParams();
    let {state} = useLocation();
    const [stateType] = state ? useState(state.type.split(",")[0]) : "";
    const [stateName] = state ? useState(state.type.split(",")[1]) : "";
    if(state) state.type = stateType;
    const [details,setDetails] = useState(null);
    const [notFoundGames,setNotFoundGames] = useState();
    const [itemOffset, setItemOffset] = useState(sessionStorage.getItem('itemOffset') ? JSON.parse(sessionStorage.getItem('itemOffset')) : 0);
    const [dataFilter,setDataFilter] = useState(sessionStorage.getItem('dataFilter') ? JSON.parse(sessionStorage.getItem('dataFilter')) : "");
    const [currentFilters,setCurrentFilters] = useState(sessionStorage.getItem('filters') ? JSON.parse(sessionStorage.getItem('filters')) : []);
    const [upcomingRelease,setUpcomingRelease] = useState(sessionStorage.getItem('upcomingRelease') ? JSON.parse(sessionStorage.getItem('upcomingRelease')) : false);
    const [paginateState, setPaginateState] = useState(sessionStorage.getItem('pageNum') ? JSON.parse(sessionStorage.getItem('pageNum')) : 0)
    if (isNaN(search)) state = {type:"name"}
    const [searchArray,setSearchArray] = useState(sessionStorage.getItem('filtersearch') ? JSON.parse(sessionStorage.getItem('filtersearch'))[0].search === search ? JSON.parse(sessionStorage.getItem('filtersearch')) : [{search:search,searchName:search,type:state.type}] : [{search:search,searchName:search,type:state.type}])
    const {testRef} = state ? getSearchData(searchArray, upcomingRelease, details, setDetails) : [];
    const {currentPageDetails, pageCount} = useSearchResults(details,dataFilter,itemOffset,userData, onUserListDel, onUserListSave, events, onEventSave, onEventDel);
    const handlePageClick = (event) => {
        setPaginateState(event.selected);
        const newOffset = (event.selected * 10) % details.length;
        setItemOffset(newOffset);
    };
    useEffect(()=>{
        sessionStorage.setItem('dataFilter',JSON.stringify(dataFilter));
    },[dataFilter])
    useEffect(()=>{
        setPaginateState(0);
        setItemOffset(0);
        sessionStorage.setItem('upcomingRelease',JSON.stringify(upcomingRelease));
    },[upcomingRelease])
    useEffect(()=>{
        setPaginateState(0);
        setItemOffset(0);
        sessionStorage.setItem('filters',JSON.stringify(currentFilters));
        sessionStorage.setItem('filtersearch',JSON.stringify(searchArray));
    },[currentFilters])
    useEffect(()=>{
        sessionStorage.setItem('pageNum',JSON.stringify(paginateState));
    },[paginateState])
    useEffect(()=>{
        sessionStorage.setItem('itemOffset',JSON.stringify(itemOffset));
    },[itemOffset])
    const addToSearch = (e) => { setSearchArray([...searchArray,e]); setCurrentFilters([...currentFilters, e.searchName]); }
    const removeFromSearch = (e) => { setSearchArray(searchArray.filter(f => f.searchName !== e)); setCurrentFilters(currentFilters.filter(f => f !== e)); sessionStorage.setItem('filters',JSON.stringify(currentFilters)); }

    //Search for games if they are on gamepass/eaplay
    const getAllGamePassGames = () => {
        if(notFoundGames)
            setNotFoundGames();
        else {
            let storedGamePassGames = localStorage.getItem("gamePassData") ? JSON.parse(localStorage.getItem("gamePassData")).data.map((item)=>item.title) : [];
            let titleFormatting = [...new Set(storedGamePassGames.map((item)=>`\"`+item.match(/^(.+?)(?: \(.+?\))?$/)[1].replace(/[^\x00-\x7F]/g, "").split(/(: .* Edition| PC.*|Xbox.*|\(.*|for W.*|- [W,P,D,Sta,Re].*| Remas.*| Defin.*| -? Game.*| Standard.*|: Final.*| Ultimate Ed.*|Windows.*|:$|\.$)/g)[0].trim()+`\"`))]
            axios({
                url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/games",
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
                },
                    data: `fields aggregated_rating,aggregated_rating_count,cover.*,first_release_date,game_modes.name,genres.name,name,player_perspectives.name,
                    platforms.name,themes.name; limit `+titleFormatting.length+`; where name=(`+titleFormatting.join(",")+`);`
                })
                .then(response => {
                    setDetails(response.data.sort((a,b)=>{if(a.name < b.name) return -1; if(a.name > b.name) return 1;}))
                    setNotFoundGames(storedGamePassGames.slice().filter((item)=>response.data.findIndex((i2)=>item.includes(i2.name)) === -1).map((item)=>item.replaceAll('"',"")))
                })
                .catch(err => {
                    console.error(err);
            });
        }
    }

    return(
        <> 
            <div className="standinBg" />
            {!testRef.current && <div style={{textAlign:"center",color:"white",fontSize:"60px"}}>Loading...</div>}
            {testRef.current &&
            <div style={{display:"grid",gridTemplateColumns:"15% 40%",justifyContent:"center",columnGap:"50px",marginLeft:"-10%"}}>
                <div style={{marginTop:"15%"}}>
                    <h6 style={{color:"white",textShadow:"1px 1px 10px #a760e9"}}><i>Note: Platform and Game Mode have Exclusive Filtering, select an additional respective filter to see normal filtering</i></h6>
                    <div className="sFilterName" style={upcomingRelease ? {width:"11vw",  backgroundImage:"linear-gradient(270deg,rgba(100, 180, 250),rgb(71, 0, 137), rgba(0,0,0,1))"} : {width:"11vw"}} onClick={()=>setUpcomingRelease(!upcomingRelease)}>Upcoming Releases Within 6 months</div>
                    <div style={{marginTop:"5px"}}>
                        <div className="sFilterName" style={{width:"11vw",backgroundImage:"linear-gradient(270deg, #e52a35, #0d1041, #0e7a0d)"}} onClick={getAllGamePassGames}>All Gamepass/EAPlay Games</div>
                        {notFoundGames && <div>
                                <h5 style={{color:"white",textShadow:"1px 1px 10px #a760e9"}}>Games Potentially Not Found Due to Formatting Issues (Use Manual Search):</h5>
                                <div style={{textAlign:"center",color:"white",textShadow:"1px 1px 10px #a760e9",display:"grid"}}>{notFoundGames?.map((item, index)=><div key={index}>{item},</div>)}</div>
                            </div>}
                    </div>
                    {currentFilters.length > 0 && !notFoundGames ? 
                                            <><div style={{display:"grid",rowGap:"10px"}}>
                                                <div className="boxTitle" style={{fontSize:"24px"}}>Current Filters</div>
                                                <div style={{width:"80px",height:"3px",backgroundColor:"white"}} />
                                                <div style={{display:"flex",fontWeight:"600",flexWrap:"wrap",gap:"5px",overflowY:"auto",maxHeight:"160px"}}>
                                                    {currentFilters.map((e,index)=><div key={index} className="sFilterNameCurr" onClick={() => removeFromSearch(e)}>{e}</div>)}
                                                </div>
                                            </div><br /></> : null}
                    {!notFoundGames &&
                        <SearchFilters
                            currentFilters={currentFilters}
                            addToSearch={(e) => addToSearch(e)}
                            removeFromSearch={(e) => removeFromSearch(e)}
                        />}
                </div>
                <div style={{width:"900px"}}>
                    <div style={{textAlign:"center",marginBottom:"10px",marginTop:"10px"}}>
                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onClick={() => setDataFilter("aggregated_rating")} />
                            <label className="btn btn-primary" htmlFor="btnradio1">Critic Rating</label>

                            <input type="radio" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" onClick={() => setDataFilter("hltbReview")} />
                            <label className="btn btn-primary" htmlFor="btnradio5">User Rating</label>

                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" onClick={() => setDataFilter("name")} autoComplete="off" />
                            <label className="btn btn-primary" htmlFor="btnradio2">Name</label>

                            <input type="radio" className="btn-check" name="btnradio" id="btnradio3" onClick={()=> setDataFilter("first_release_date")} autoComplete="off" defaultChecked={true} />
                            <label className="btn btn-primary" htmlFor="btnradio3">Release Date</label>

                            <input type="radio" className="btn-check" name="btnradio" id="btnradio4" onClick={() => { setNotFoundGames(); setSearchArray([searchArray[0]]); setCurrentFilters([]); setDataFilter(""); setPaginateState(0); setItemOffset(0); setUpcomingRelease(false); }} autoComplete="off" />
                            <label className="btn btn-danger" htmlFor="btnradio4">Clear Filters</label>
                        </div>
                    </div>
                    {stateName && <div className='boxTitle' style={{textAlign:"center"}}>Current Search: {stateName}</div>}
                    <ReactPaginate
                        activeClassName={'item activePage '}
                        breakClassName={'item '}
                        containerClassName={'pagination'}
                        disabledClassName={'disabled-page'}
                        nextClassName={"item next "}
                        pageClassName={'item pagination-page '}
                        previousClassName={"item previous"}
                        breakLabel="..."
                        nextLabel={<i className="fa fa-arrow-circle-right" aria-hidden="true"></i>}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel={<i className="fa fa-arrow-circle-left" aria-hidden="true"></i>}
                        renderOnZeroPageCount={null}
                        forcePage={paginateState}
                    />
                    {currentPageDetails && (currentPageDetails.length > 0 ? 
                        <div style={{display:"grid",gap:"20px"}}>
                            {currentPageDetails}
                            <ReactPaginate
                                activeClassName={'item activePage '}
                                breakClassName={'item '}
                                containerClassName={'pagination'}
                                disabledClassName={'disabled-page'}
                                nextClassName={"item next "}
                                pageClassName={'item pagination-page '}
                                previousClassName={"item previous"}
                                breakLabel="..."
                                nextLabel={<i className="fa fa-arrow-circle-right" aria-hidden="true"></i>}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel={<i className="fa fa-arrow-circle-left" aria-hidden="true"></i>}
                                renderOnZeroPageCount={null}
                                forcePage={paginateState}
                            />
                        </div>
                    : 
                        <div style={{textAlign:"center",color:"white",fontSize:"50px"}}>
                            No Results Found...
                            <ReactPaginate
                                activeClassName={'item activePage '}
                                breakClassName={'item '}
                                containerClassName={'pagination'}
                                disabledClassName={'disabled-page'}
                                nextClassName={"item next "}
                                pageClassName={'item pagination-page '}
                                previousClassName={"item previous"}
                                breakLabel="..."
                                nextLabel={<i className="fa fa-arrow-circle-right" aria-hidden="true"></i>}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel={<i className="fa fa-arrow-circle-left" aria-hidden="true"></i>}
                                renderOnZeroPageCount={null}
                                forcePage={paginateState}
                            />
                        </div>
                    )}
                </div>
            </div>
            }
        </>
    )
}

export default SearchResults;