export const EventListTable = ({eventArray, day}) => {
    return(
        <div style={{margin:"auto",borderRadius:"10px",overflowY:"auto", height:"850px"}}>
            <table className="table table-striped table-hover table-dark" style={{width:"100%",fontWeight:"600",verticalAlign:"middle",border:"2px solid black"}}>
                <thead style={{position:"sticky",top:"0",backgroundColor:"#2b3035",color:"#eaf4f9"}}>
                    <tr>
                        {!day && <th></th>}
                        <th style={{fontWeight:"600"}}>Event Name</th>
                        <th style={{fontWeight:"600",width:"300px"}}>Event Game</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {eventArray}
                </tbody>
            </table>
        </div>
    )
}

export default EventListTable;