import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserListButtons from "./UserListButtons";

export const SteamGrab = ({userData, onUserListUpdate, onUserListSave, onUserListDel, events, onEventSave}) => {
    const navigate = useNavigate();
    const [steamID,setSteamID] = useState(localStorage.getItem("steamID"));
    const [saveSteamID,setSaveSteamID] = useState(true);
    const [showSteam, setShowSteam] = useState(false);
    const [steamFindLoading, setSteamFindLoading] = useState(false);
    const [gameFindLoading, setGameFindLoading] = useState(false);
    const [foundGames,setFoundGames] = useState();
    const [notFoundGames,setNotFoundGames] = useState([]);
    const [updatedGameNames,setUpdatedGameNames] = useState([]);
    const [notUpdatedGameNames,setNotUpdatedGameNames] = useState([]);
    const [notFoundGameNames,setNotFoundGameNames] = useState([]);
    const [errorMessage,setErrorMessage] = useState();
    const [showTooltip,setShowTooltip] = useState(false);
    const gamePassData = localStorage.getItem("gamePassData") ? JSON.parse(localStorage.getItem("gamePassData")).data : [];

    const recentSteamGameGrab = (e) => {
        e.preventDefault();
        setShowSteam(true);
        setSteamFindLoading(true);
        setErrorMessage();
        setUpdatedGameNames([]);
        setNotUpdatedGameNames([]);
        setNotFoundGameNames([]);
        setNotFoundGames([]);
        setFoundGames();
        if(saveSteamID) {
            if(localStorage.getItem("steamID") !== steamID)
                localStorage.setItem("steamID", steamID);
        } else {
            if(localStorage.getItem("steamID"))
                localStorage.removeItem("steamID");
        }
        axios.get("https://game-cal-cors.onrender.com/https://api.steampowered.com/IPlayerService/GetRecentlyPlayedGames/v0001/?key=E2D13107EA41850DA6F7F9FCC94FBCDB&steamid="+steamID+"&format=json")
            .then((res)=>{
                let requestData = ``;
                res.data.response.games.forEach(game => {
                    const gameNameTrim = game.name.replace(/[^a-zA-Z0-9!'.,-: ]/g, "").toLowerCase();
                    if(!userData.some((game2) => game2.game.name.toLowerCase() === gameNameTrim)) {
                        setNotFoundGames(prevState => [...prevState,game])
                        setNotFoundGameNames(prevState => [...prevState,game.name])
                        if(requestData.length === 0)
                            requestData = `name~\"`+gameNameTrim+`\"*`
                        else
                            requestData += ` | name~\"`+gameNameTrim+`\"*`
                    }
                    else {
                        const tempUserData = [...userData];
                        const ind = tempUserData.findIndex(e => e.game.name.toLowerCase() === gameNameTrim);
                        if(!tempUserData[ind].last_steam_update || (tempUserData[ind].last_steam_update && tempUserData[ind].last_steam_update < game.playtime_forever)) {
                            setUpdatedGameNames(prevState => [...prevState,game.name]);
                            tempUserData[ind].user_hours = (Number(tempUserData[ind].user_hours) !== 0 ? (Number(tempUserData[ind].user_hours) + Number(game.playtime_forever - (tempUserData[ind].last_steam_update ? tempUserData[ind].last_steam_update : 0))/60).toFixed(1) : (Number(game.playtime_forever)/60).toFixed(1));
                            tempUserData[ind].last_steam_update = game.playtime_forever;
                            onUserListUpdate(tempUserData);
                        }
                        else {
                            setNotUpdatedGameNames(prevState => [...prevState,game.name]);
                        }
                    }
                })
                setSteamFindLoading(false);
                if(requestData.length !== 0) {
                    setGameFindLoading(true)
                    axios({
                    url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/games",
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
                    },
                        data: `fields category,cover.*,first_release_date,game_modes.name,genres.name,name; limit `+requestData.length+`; where (`+requestData+`);`
                    }).then((res2) => {setGameFindLoading(false); setFoundGames(res2.data);})
                }
            }).catch(err => {setErrorMessage("Bad Steam ID"); setShowSteam(false); console.log(err);});
    }

    const navToGame = (game) => {
        navigate("/game-details/"+game.id);
        window.location.reload(false);
    }

    return(
        <div>
            {errorMessage && <h4 style={{color:"red"}}><i>{errorMessage}</i></h4>}
            <form style={{display:"grid",margin:"auto"}} onSubmit={recentSteamGameGrab}>
                <div style={{display:"grid",gridTemplateColumns:"10% auto"}}>
                    <div style={{color:"black",backgroundColor:"white",borderRadius:"20px",margin:"auto",paddingLeft:"7px",paddingRight:"7px"}} onMouseOver={()=>setShowTooltip(true)} onMouseOut={()=>setShowTooltip(false)}><i class="fa fa-question" /></div>
                    {showTooltip && <div style={{position:"absolute",zIndex:"1",display:"inline-block",width:"160px",backgroundColor:"black",color:"white",marginTop:"35px",borderRadius:"20px",textAlign:"center",boxShadow:"1px 1px 10px #a760e9"}}>Steam Id is found under Account Name in Account Details.<div className="steamSepLine" style={{backgroundImage: "linear-gradient(270deg, #a760e9 0%, rgba(100, 180, 250) 50%, #a760e9 100%)",width:"50%",margin:"auto"}} />If the recent game does not have hours played then it will put your total Steam hours.</div>}
                    <input type="text" value={steamID ? steamID : ""} onChange={(e) => !isNaN(e.target.value.trim()) && setSteamID(e.target.value.trim())} placeholder="Steam ID" required />
                </div>
                <div style={{display:"grid",gridTemplateColumns:"auto auto",columnGap:"5%", color:"white"}}>
                    <div style={{margin:"auto"}}>
                        Remember Steam Id
                        <input type="checkbox" className="btn btn-primary btn-sm" checked={saveSteamID} onChange={() => setSaveSteamID(prevState => !prevState)} />
                    </div>
                    <button type="submit" className="btn btn-primary btn-sm">Upload Recently Played Steam Games and Hours</button>
                </div>
            </form>
            {showSteam && !steamFindLoading ?
                <div className="uListSteamContainer">
                    <div style={{wordWrap:"break-word"}}>
                        {updatedGameNames.length > 0 &&
                            <div>
                            <h5>Games Updated:</h5>
                            {updatedGameNames.join(", ")}
                            <div className="steamSepLine" style={{backgroundColor:"red"}} />
                        </div>
                        }
                        {notUpdatedGameNames.length > 0 &&
                        <div>
                            <h5>Games Already Updated Previously:</h5>
                            {notUpdatedGameNames.join(", ")}
                            <div className="steamSepLine" style={{backgroundColor:"green"}} />
                        </div>
                        }
                        {notFoundGameNames.length > 0 &&
                        <div>
                            <h5>Games Not In List:</h5>
                            {notFoundGameNames.join(", ")}
                            <div className="steamSepLine" style={{backgroundColor:"blue"}} />
                        </div>
                        }
                    </div>
                    {foundGames ?
                        <div style={{maxHeight:"42vh", overflowY:"scroll"}}>
                            <h5>Here is what we found for games not in your lists:</h5>
                            {foundGames.length > 0 ?
                                foundGames.map((item,index) => {
                                    const gamePassDataFound = gamePassData?.find((game) => game.title.replace(/[!'.,-:]/g, "").toLowerCase().includes(item.name.replace(/[!'.,-:]/g, "").toLowerCase()));
                                    return(
                                        <div className="sUListItem" key={index} style={{display:"grid", gridTemplateColumns:"55% 45%"}}>
                                            <a onClick={()=>navToGame(item)} style={{display:"grid",gridTemplateColumns:"auto auto",justifyContent:"start",columnGap:"5px"}}>
                                                {item.cover ? <img src={item.cover.url} style={{borderRadius:"12px"}} /> : <img className="gameCoverImg" style={{width:"100%"}} src="https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg" width="130px" height="185px" />}
                                                <div style={{margin:"auto",wordBreak:"break-word"}}>{item.name}</div>
                                            </a>
                                            <div style={{margin:"auto",width:"80%"}}>
                                            <UserListButtons
                                            game={item}
                                            onUserListSave={onUserListSave}
                                            onUserListDel={onUserListDel}
                                            userData={userData}
                                            searchBar={true}
                                            steamGrab={notFoundGames}
                                            events={events}
                                            onEventSave={onEventSave}
                                            gamePassData={gamePassDataFound}
                                            />
                                            </div>
                                        </div>
                                    )
                                })
                            :
                                <div>No Games Found. Try Manually Searching For Them In Find Games.</div>
                            }
                        </div>
                    :
                        gameFindLoading &&
                            <h5>
                                Looking For Submitted Games Not In Your Lists...
                            </h5>
                    }
                </div>
            : showSteam &&
                <div>Getting Steam Data...</div>
            }
        </div>
    )
}

export default SteamGrab;