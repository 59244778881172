import { useState } from "react"

export const WheelButtons = ({userData}) => {
    const [copyCats, setCopyCats] = useState([])

    const copyGames = () => {
        let donePrep = []
        userData.map((e) => {
            if(copyCats.some(item => item === e.category) && e.game.first_release_date*1000 <= (new Date().getTime())) donePrep.push(e.game.name);
        })
        let wheelFormat = ""
        donePrep.map((item)=>wheelFormat+=(item+'\n'))
        navigator.clipboard.writeText(wheelFormat);
    }

    return (
        <div style={{display:"grid",gridTemplateColumns:"35% auto",columnGap:"5px",margin:"auto"}}>
                <div style={{margin:"auto"}}>
                    <button className="btn btn-primary" onClick={copyGames}>Send Games to Clipboard</button>
                </div>
                <div className="wheelTitles">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onClick={()=> copyCats.indexOf(0) < 0 ? setCopyCats([...copyCats, 0]) : setCopyCats(copyCats.filter(e => e !== 0))} id="currPlayingCheck" />
                        <label className="form-check-label" htmlFor="currPlayingCheck">
                            Currently Playing
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onClick={()=> copyCats.indexOf(1) < 0 ? setCopyCats([...copyCats, 1]) : setCopyCats(copyCats.filter(e => e !== 1))} id="planningPlayCheck" />
                        <label className="form-check-label" htmlFor="planningPlayCheck">
                            Planning to Play
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onClick={()=> copyCats.indexOf(3) < 0 ? setCopyCats([...copyCats, 3]) : setCopyCats(copyCats.filter(e => e !== 3))} id="onHoldCheck" />
                        <label className="form-check-label" htmlFor="onHoldCheck">
                            On Hold
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onClick={()=> copyCats.indexOf(2) < 0 ? setCopyCats([...copyCats, 2]) : setCopyCats(copyCats.filter(e => e !== 2))} id="completedCheck" />
                        <label className="form-check-label" htmlFor="completedCheck">
                            Completed
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onClick={()=> copyCats.indexOf(4) < 0 ? setCopyCats([...copyCats, 4]) : setCopyCats(copyCats.filter(e => e !== 4))} id="droppedCheck" />
                        <label className="form-check-label" htmlFor="droppedCheck">
                            Dropped
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onClick={()=> copyCats.indexOf(5) < 0 ? setCopyCats([...copyCats, 5]) : setCopyCats(copyCats.filter(e => e !== 5))} id="watchlistCheck" />
                        <label className="form-check-label" htmlFor="watchlistCheck">
                            Watchlist
                        </label>
                    </div>
                </div>
            </div>
    )
}

export default WheelButtons;