import React, { useEffect, useState } from 'react';
import { AwardData } from './AwardData';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Tab } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';

export const AwardContainer = ({userData,onUserListUpdate}) => {
    const [personalAwardTabIndex, setPersonalAwardTabIndex] = useState("0");
    const [userYears,setUserYears] = useState([]);
    const [awardYears,setAwardYears] = useState();
    const [awardYearElements,setAwardYearElements] = useState();

    useEffect(()=>{
        const years = userData && new Set(userData.map((item)=> {
            if(item.played_year <= new Date().getFullYear())
                return Number(item.played_year)
            if(new Date(item.game.first_release_date*1000).getTime() <= new Date().getTime())
                return new Date(item.game.first_release_date*1000).getFullYear()
        }).filter((item)=>item !== NaN && item > 2000).sort((a,b)=>b-a));
        setAwardYears(Array.from(years))
    },[userData])

    useEffect(()=>{
        if(awardYears) {
            const yearElements = awardYears.map((year,index)=> {
                return(<Tab key={index} label={year.toString()} value={(index+2).toString()} sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />)
            })
            setAwardYearElements(yearElements)
        }
    },[awardYears])

    const updateAllYears = () => {
        const temp = [...userData]
        userYears.map((year) => {
            if(year.year >= 1900 && year.year <= new Date().getFullYear())
                temp.find((item)=>item.game.id === year.id).played_year = year.year
        })
        onUserListUpdate(temp)
        setUserYears([])
    }

  return (
    <TabContext value={personalAwardTabIndex}>
    <TabList orientation="vertical" variant="scrollable" onChange={(e, tabIndex) => setPersonalAwardTabIndex(tabIndex)} sx={{borderRight:"1px solid #a760e9",".Mui-selected": {color:"white !important"}}} TabIndicatorProps={{style:{backgroundImage: "linear-gradient(180deg, #a760e9 0%, rgba(100, 180, 250) 50%, #a760e9 100%)"}}}>
        <Tab label="Mass Ajust Year Played" value="0" sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />
        <Tab label="All Time" value="1" sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} />
        {awardYearElements}
    </TabList>
    <TabPanel value="0" sx={{padding:"5px"}}>
        <div style={{textAlign:"center"}}>
            <button className="btn btn-success" style={{width:"30%"}} onClick={updateAllYears} disabled={userYears.length === 0}>Update All</button>
            <h4><i style={{color:"red"}}>Game must have hours played<br/>Inputted Year must be between 1900 and the current year</i></h4>
        </div>
        <div style={{display:"grid",gridTemplateColumns:"auto auto auto",justifyContent:"start",columnGap:"5px",maxHeight:"580px",overflowY:"scroll",color:"white"}}>
            {userData.slice().filter((userDataGame)=> Number(userDataGame.user_hours) > 0 && userDataGame.game.first_release_date && new Date(userDataGame.game.first_release_date*1000) <= new Date()).map((item,index)=> 
                <div key={index} style={{display:"grid",gridTemplateColumns:"25% 30% 20% 20%",justifyContent:"start",columnGap:"5px"}}>
                    {item.game.cover ? <img src={item.game.cover.url} style={{borderRadius:"12px"}} /> : <img className="gameCoverImg" src="https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg" width="90px" height="90px" />}
                    <span style={{margin:"auto"}}>
                        {item.game.name}
                    </span>
                    <div style={{margin:"auto"}}>
                        Played In: {item.played_year ? item.played_year : "N/A"}
                    </div>
                    <input
                        type="number"
                        placeholder="Year..."
                        maxLength={4}
                        value={userYears.some((year) => year.id === item.game.id) ? userYears.find((year) => year.id === item.game.id).year : ""}
                        onChange={(e) => {
                            if(userYears.some((year)=>year.id === item.game.id)) {
                                let temp = [...userYears];
                                if(e.target.value !== '')
                                    temp[userYears.findIndex((year)=>year.id === item.game.id)] = {id:item.game.id,year:e.target.value};
                                else
                                    temp = temp.filter((year)=>year.id !== item.game.id)
                                setUserYears(temp);
                            } else 
                                setUserYears([...userYears,{id:item.game.id,year:e.target.value}])
                            }}
                        style={{width:"80px",height:"30px",marginLeft:"10px",margin:"auto"}}
                    />
                </div>
            )}
        </div>
    </TabPanel>
    <AwardData userData={userData} personalAwardTabIndex={personalAwardTabIndex} years={awardYears} val="1" />
    {awardYears && awardYears.map((year,index)=>
        <AwardData key={index} userData={userData} personalAwardTabIndex={personalAwardTabIndex} years={awardYears} year={year} val={(index+2).toString()} />
                                )}
</TabContext>
  );
};

export default AwardContainer;