import { useState } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { SearchBar } from './SearchBar';

export const Nav = ({userData, onUserListSave, onUserListDel, events, onEventSave}) => {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    }

    return (
        <>
            <nav className="navbar bg-dark navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
                <div style={{display:"grid",gridTemplateColumns:"auto auto auto",alignItems:"center",width:"95%",margin:"auto"}}>
                    <ul className="navbar-nav mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink to="/" onClick={clearInput} id="navLinkColor" className="nav-link">Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="user-profile" onClick={clearInput} id="navLinkColor" className="nav-link">Game Lists</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="search-results/all" onClick={clearInput} id="navLinkColor" className="nav-link">Find Games</NavLink>
                        </li>
                    </ul>
                    <div style={{marginLeft:"12%",paddingTop:"5px"}}>
                        <SearchBar 
                            check={false}
                            onUserListSave={onUserListSave}
                            onUserListDel={onUserListDel}
                            userData={userData}
                            clearInput={clearInput}
                            filteredData={filteredData}
                            setFilteredData={e => setFilteredData(e)}
                            wordEntered={wordEntered}
                            setWordEntered={e => setWordEntered(e)}
                            events={events}
                            onEventSave={onEventSave}
                        />
                    </div>
                    <div style={{textAlign:"right",paddingTop:"5px"}} />
                </div>
            </nav>
            <div style={{maxHeight:"95vh",overflowY:"auto"}}>
                <Outlet />
            </div>
        </>
    )
}

export default Nav;