export function dataMapping(details, word, navigate) {
    if (details) {
        if (word === "bundles" || word === "expansions" || word === "dlcs" || word === "parent_game" || word === "remakes" || word === "remasters" || word === "similar_games" || word === "standalone_expansions") {
            if (details.hasOwnProperty(word) && details[word]) {
                if (details[word].length > 0) {
                        return (details[word].map((e, index) => e.cover ? <div className="gameOuterBorder" key={index}>
                                                                            <div className="gameCover" onClick={() => { navigate("/game-details/"+e.id); window.location.reload(false); }}>
                                                                                <img className="gameCoverImg" src={`//images.igdb.com/igdb/image/upload/t_cover_med/${e.cover.image_id}.jpg`} />
                                                                                <div className="gameCoverName">{e.name}</div>
                                                                            </div>
                                                                        </div> : <div className="gameOuterBorder" key={index}>
                                                                                    <div className="gameCover" onClick={() => { navigate("/game-details/"+e.id); window.location.reload(false); }}>
                                                                                        <img className="gameCoverImg" src="https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg" width="130px" height="185px" />
                                                                                        <div className="gameCoverName">{e.name}</div>
                                                                                    </div>
                                                                                </div>))
                } else {
                    return (details[word]["cover"] ? <div className="gameOuterBorder">
                                                        <div className="gameCover" onClick={() => { navigate("/game-details/"+details[word]["id"]); window.location.reload(false); }}>
                                                            <img className="gameCoverImg" src={`//images.igdb.com/igdb/image/upload/t_cover_med/${details[word]["cover"]["image_id"]}.jpg`} />
                                                            <div className="gameCoverName">{details[word]["name"]}</div>
                                                        </div>
                                                    </div> : <div className="gameOuterBorder">
                                                                <div className="gameCover" onClick={() => { navigate("/game-details/"+details[word]["id"]); window.location.reload(false); }}>
                                                                    <img className="gameCoverImg" src="https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg" width="130px" height="185px" />
                                                                    <div className="gameCoverName">{details[word]["name"]}</div>
                                                                </div>
                                                            </div>)
                }
            }
        } else if (word === "game_engines") {
            if (details.hasOwnProperty(word) && details[word]) return (details[word].map((e, index) => <span key={index}>{e.name}</span>));
        } else if (word === "involved_companies" && details.hasOwnProperty(word)) {
            return (details.involved_companies.map((e, index) => 
                e.developer ? <div key={index}>Developer: {e.company.name}</div> : 
                e.porting ? <div key={index}>Porting Company: {e.company.name}</div> : 
                e.publisher ? <div key={index}>Publisher: {e.company.name}</div> : 
                e.supporting ? <div key={index}>Supporting: {e.company.name}</div> : null
            ))
        } else if (word === "franchises" || word === "keywords" || word === "game_modes" || word === "genres" || word === "player_perspectives" || word === "platforms" || word === "themes") {
            if (details.hasOwnProperty(word) && details[word])
                return (details[word].map((e, index) => {
                    if(index + 1 === details[word].length) { return <a key={index} className="gameDInfoLink" onClick={() => { navigate("/search-results/"+e.id, {state: {type:word+","+e.name}}); window.location.reload(false); }}>{e.name} </a> }
                    else { return <a key={index} className="gameDInfoLink" onClick={() => { navigate("/search-results/"+e.id, {state: {type:word+","+e.name}}); window.location.reload(false); }}>{e.name}, </a>}
                }))
        } else if (word === "websites") {
            if (details.hasOwnProperty(word) && details[word])
                return (details["websites"].map((e, index) => {
                    switch(e.category) {
                        case 1: return <div key={index}><i className="fa fa-globe" style={{scale:"1.5"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Official Website</a></div>
                        case 2: return <div key={index}><i className="fa fa-heart iScale" style={{color:"lightgreen",scale:"1.5"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Wikia</a></div>
                        case 3: return <div key={index}><i className="fa fa-wikipedia-w iScale" style={{backgroundColor:"white",borderRadius:"4px",scale:"1.25"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Wikipedia</a></div>
                        case 4: return <div key={index}><i className="fa fa-facebook-square iScale" style={{color:"#4267B2",scale:"1.5"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Facebook</a></div>
                        case 5: return <div key={index}><i className="fa fa-twitter-square iScale" style={{color:"#1D9BF0",scale:"1.5"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Twitter</a></div>
                        case 6: return <div key={index}><i className="fa fa-twitch iScale" style={{color:"#6441a5",scale:"1.5"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Twitch</a></div>
                        case 8: return <div key={index}><i className="fa fa-instagram iScale" style={{color:"#C13584",scale:"1.5"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Instagram</a></div>
                        case 9: return <div key={index}><i className="fa fa-youtube-play iScale" style={{color:"red",scale:"1.5"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Youtube</a></div>
                        case 10: return <div key={index}><iconify-icon icon="wpf:iphone" style={{scale:"1.5"}} /><a className="gameDetailsSite" href={e.url}>IPhone</a></div>
                        case 11: return <div key={index}><iconify-icon icon="wpf:iphone" style={{scale:"1.5"}} /><a className="gameDetailsSite" href={e.url}>IPad</a></div>
                        case 12: return <div key={index}><i className="fa fa-mobile" style={{scale:"1.5"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Android</a></div>
                        case 13: return <div key={index}><i className="fa fa-steam-square" style={{color:"#2a475e",scale:"1.5"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Steam</a></div>
                        case 14: return <div key={index}><i className="fa fa-reddit-square" style={{color:"orange",scale:"1.5"}} aria-hidden="true" /><a className="gameDetailsSite" href={e.url}>Reddit</a></div>
                        case 16: return <div key={index}><iconify-icon icon="simple-icons:epicgames" className="iScale" style={{color:"grey",scale:"1.5"}} /><a className="gameDetailsSite" href={e.url}>Epic Games</a></div>
                        case 17: return <div key={index}><iconify-icon icon="mdi:gog" style={{scale:"1.5"}} /><a className="gameDetailsSite" href={e.url}>GoG</a></div>
                        case 18: return <div key={index}><iconify-icon icon="ic:baseline-discord" style={{color:"#7289DA",scale:"1.5"}} /><a className="gameDetailsSite" href={e.url}>Discord</a></div>
                    }
                }))
        }

    }
}