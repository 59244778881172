export const NoPage = () => {
    return(
        <>
            <div className="standinBg" />
            <div style={{textAlign:"center",fontSize:"36px",color:"white",textShadow:"1px 1px 1px black"}}>
                <div>Bad Link</div>
                <div>No Page Here</div>
            </div>
        </>
    )
}

export default NoPage;