import { UserListButtons } from "./UserListButtons";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { UpdateGameModal } from "../Modals/UpdateGameModal";

export const UserListGame = ({gameData,userData,user,awardGames,awarded,addToAwards,delFromAwards,onUserListDel,onUserListSave,onUserListUpdate,show}) => {
    const navigate = useNavigate()
    const [showGame,setShowGame] = useState(show)
    const [clicky,setClicky] = useState(false)
    const [visible,setVisable] = useState(false)
    const [clicked, setClicked] = useState()
    const [refreshing,setRefreshing] = useState(false)
    const awardInd = awardGames && awardGames.findIndex((item)=>item && item.game.name === gameData.game.name);
    const hltbHours = gameData.hltb && gameData.hltb.map((hltbData,index) => {
        if(hltbData.gameName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[!'.,-:]/g, "").replace(/[&]/g, "and") === gameData.game.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[!'.,-:]/g, ""))
        return(
            <div key={index}>
                <div style={{display:"grid",gridTemplateColumns:"auto",rowGap:"5px"}}>
                    <div>
                        <div style={{fontSize:"18px",fontWeight:"700"}}>Main</div>
                        <div style={{fontSize:"18px"}}>{(hltbData.mainHours / 3600).toFixed(1)} Hours</div>
                        <div style={{fontSize:"14px"}}>{hltbData.mainCount} Submissions</div>
                    </div>
                    <div>
                        <div style={{fontSize:"18px",fontWeight:"700"}}>Main+Extra</div>
                        <div style={{fontSize:"18px"}}>{(hltbData.mainExtraHours / 3600).toFixed(1)} Hours</div>
                        <div style={{fontSize:"14px"}}>{hltbData.mainExtraCount} Submissions</div>
                    </div>
                    <div>
                        <div style={{fontSize:"18px",fontWeight:"700"}}>100 Percent</div>
                        <div style={{fontSize:"18px"}}>{(hltbData.completionHours / 3600).toFixed(1)} Hours</div>
                        <div style={{fontSize:"14px"}}>{hltbData.completionCount} Submissions</div>
                    </div>
                </div>
            </div>
        )
    })

    useEffect(()=>{
        setShowGame(show)
    },[show])

    const handleClick = () => {
        if(!clicky && (!gameData.lastHLTBUpdate || new Date().getTime() - gameData.lastHLTBUpdate > 86400000))
            onRefresh()
        setVisable(!visible)
        setClicky(!clicky)
    }

    const onRefresh = () => {
        setRefreshing(true)
        fetch(`https://game-cal-cors.onrender.com/https://shouldiplay-api.up.railway.app/hltb/${encodeURIComponent(gameData.game.name.toLowerCase())}`)
        .then((response) => response.json())
        .then((result) => {
            const hltbHours = result.data.map((e)=>{
                return({gameName:e.game_name,gameType:e.game_type,mainHours:e.comp_main,mainCount:e.comp_main_count,mainExtraHours:e.comp_plus,mainExtraCount:e.comp_plus_count,completionHours:e.comp_100,completionCount:e.comp_100_count,hltbReview:e.review_score,hltbReviewCount:e.count_review})
            })
            const tempUserData = [...userData]
            const ind = tempUserData.findIndex(e => e.game.name.toLowerCase() === gameData.game.name.toLowerCase())
            tempUserData[ind].hltb = hltbHours;
            tempUserData[ind].lastHLTBUpdate = new Date().getTime();
            onUserListUpdate(tempUserData)
            setRefreshing(false)
        }).catch(err => {
            setRefreshing(false)
            console.log(err)
        })
    }

    const onUpdate = (data) => {
        const tempUserData = [...userData]
        const ind = tempUserData.findIndex(e => e.game.name.toLowerCase() === data.game.name.toLowerCase())
        tempUserData[ind] = data
        onUserListUpdate(tempUserData)
        setClicked(null)
    }

    const medalColor = awardInd === 7 ? "radial-gradient(brown,rgba(0, 0, 0, 1))" : awardInd === 8 ? "radial-gradient(grey,rgba(0, 0, 0, 1))" : awardInd === 9 ? "radial-gradient(gold,rgba(0, 0, 0, 1))" : "radial-gradient(#a760e9,rgba(167, 96, 233,1),rgba(0, 0, 0, 1))";

    return(
        showGame || !awarded ?
            <div style={{display:"flex",animation:"fadeIn 0.7s",position:"relative",borderRadius:"20px",boxShadow:"10px 5px 10px #a760e9"}}>
                <div className="uListGame">
                    <div style={{position:"absolute",zIndex:"1",top:"10px",left:"10px",display:"flex"}}>
                        {!awardGames ?
                            <UserListButtons
                                game={gameData.game}
                                userListCat={gameData.category}
                                onUserListSave={onUserListSave}
                                onUserListDel={onUserListDel}
                                userData={userData}
                                searchBar={true}
                            />
                        :
                            awarded ?
                                <div style={{backgroundImage: medalColor,borderRadius:"50%",padding:"5px",paddingLeft:"15px",paddingRight:"15px",fontSize:"18px",fontWeight:"700",boxShadow:"1px 1px 10px blue"}}>{10 - awardInd}</div>
                            :
                                awardGames.some((item)=>item && item.game.name === gameData.game.name) ?
                                    <button className="btn btn-danger" style={{fontSize:"21px"}} onClick={()=>delFromAwards(gameData.game)}><i className="fa fa-trash" aria-hidden="true" /></button>
                                :
                                    <button className="btn btn-success" style={{fontSize:"21px"}} onClick={()=>addToAwards(gameData.game)}><i className="fa fa-plus" aria-hidden="true" /></button>
                        }
                    </div>
                    {!awardGames ?
                        <div style={{position:"absolute",zIndex:"1",top:"10px",right:"10px"}}>
                            {!user && <button className="btn btn-primary" style={{fontSize:"21px"}} title="Update Hours/Ratings" onClick={()=>setClicked(gameData)}><i className="fa fa-pencil-square" aria-hidden="true" /></button>}
                            <button className="btn btn-secondary" style={{fontSize:"21px"}} onClick={handleClick}><i className="fa fa-arrow-circle-right" aria-hidden="true" /></button>
                        </div>
                    : awarded &&
                        <div style={{position:"absolute",zIndex:"1",top:"10px",right:"10px"}}>
                            <button className="btn btn-success" onClick={()=>setShowGame(false)} title="Hide Game"><i className="fa fa-eye" aria-hidden="true" /></button>
                        </div>
                    }
                    <div className="gameCover" style={{width:"100%",paddingTop:"0px"}} onClick={() => !awarded && navigate("/game-details/"+gameData.game.id)}>
                        {gameData.game.cover ? <img className="gameCoverImg" style={{width:"100%"}} src={`//images.igdb.com/igdb/image/upload/t_cover_big/${gameData.game.cover.image_id}.jpg`} /> : <img className="gameCoverImg" style={{width:"264px"}} src="https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg" />}
                        
                        <div className="gameCoverName" style={{fontSize: awardGames ? "19px" : "26px",textShadow: awarded ? "1px 1px 1px #a760e9" : "1px 1px 1px black",fontWeight:"600"}}>
                            {gameData.onEAPlay && !awardGames && <div className="thirdPartyBanner" style={{backgroundImage:"linear-gradient(270deg, #e52a35, #0d1041"}}>On EA Play</div>}
                            {gameData.onGamePass && !awardGames && <div className="thirdPartyBanner" style={{backgroundColor:"#0e7a0d"}}>On GamePass</div>}
                            {gameData.game.name}
                        </div>
                    </div>
                    <div style={{display:"grid",gridTemplateColumns:"auto auto",textAlign:"center",columnGap:"10px",color:"white",fontWeight:"600",fontSize:awarded && "15px"}}>
                            {gameData.user_rating ? <div>{gameData.user_rating}<div>Personal Rating</div></div> : <div>N/A<div>Personal Rating</div></div>}
                            {gameData.user_hours ? <div>{gameData.user_hours}<div>Hours Played</div></div> : <div>0<div>Hours Played</div></div>}
                    </div>
                </div>
                <div style={{backgroundImage:"linear-gradient(to left, rgb(0, 0, 0,0.025),rgba(0,0,0,0.4), rgba(0,0,0,0.7), black)",marginLeft:"-10px"}} className={visible?'fadeIn':'fadeOut'}>
                    {clicky && <div style={{marginLeft:"20px",marginRight:"20px",color:"white",textAlign:"center"}}>
                        <div style={{display:"grid",gridTemplateColumns:"auto",rowGap:"5px",marginBottom:"10px",marginTop:"10px"}}>
                            <div>Release Date: {gameData.game.first_release_date ? ((new Date(gameData.game.first_release_date*1000).getUTCMonth()+1) +"/"+new Date(gameData.game.first_release_date*1000).getUTCDate()+"/"+new Date(gameData.game.first_release_date*1000).getUTCFullYear()) : "TBD"}</div>
                            <div>Added On {new Date(gameData.date_added).toDateString()}</div>
                            {(gameData.game.aggregated_rating && gameData.game.aggregated_rating_count) && <div><div style={{fontSize:"20px"}}>{Math.round(gameData.game.aggregated_rating)}%</div><div style={{fontSize:"14px"}}>{gameData.game.aggregated_rating_count} Critic Reviews</div></div>}
                            {gameData.hltb.length > 0 && gameData.hltb[0].hltbReview && <div><div style={{fontSize:"20px"}}>{Math.round(gameData.hltb[0].hltbReview)}%</div><div style={{fontSize:"14px"}}>{gameData.hltb[0].hltbReviewCount} User Reviews</div></div>}
                        </div>
                        {gameData.hltb.length > 0 && <div>{hltbHours}</div>}
                        {gameData.hltb.length === 0 && <div>No HLTB Data</div>}
                        {refreshing &&
                            <div>
                                <div style={{position:"absolute", zIndex:"20",backgroundColor:"#a760e9",opacity:"0.7",width:"40%",top:"25%",height:"70%",borderRadius:"20px"}}/>
                                <div style={{position:"absolute", zIndex:"20",top:"50%", left:"65%",fontSize:"30px",textShadow:"2px 2px 2px black"}}>Refreshing...</div>
                            </div>
                        }
                    </div>}
                </div>
                {clicked &&
                    <UpdateGameModal 
                        userData={clicked}
                        onUpdate={onUpdate}
                        onClose={() => setClicked(null)}
                    />
                }
            </div>
        :
            <div style={{position:"relative",width:"100%",height:"100%",display:"flex",justifyContent:"center",textAlign:"center",border:"2px dashed #a760e9",borderRadius:"20px",boxShadow:"10px 5px 10px #a760e9"}}>
                <div style={{backgroundImage: medalColor,borderRadius:"50%",padding:"5px",paddingLeft:"15px",paddingRight:"15px",fontSize:"18px",fontWeight:"700",boxShadow:"1px 1px 10px blue",position:"absolute",zIndex:"1",top:"10px",left:"10px",display:"flex",maxHeight:"430px"}}>{10 - awardInd}</div>
                <a style={{margin:"auto"}} onClick={()=>setShowGame(true)}><div style={{margin:"auto",color:"#a760e9",textShadow:"1px rgba(100, 180, 250)"}}><i className="fa fa-eye" aria-hidden="true" /><br/>Click To Reveal</div></a>
            </div>
        
    )
}

export default UserListGame;