import axios from "axios";

export const apiToken = () => {
    const text = {client_id:'1hj6jzp6fwobjq4mrfzh9s3300cpk1',client_secret:'rf9gl52in21o6cqqsl9qh8s801t2k0',grant_type:'client_credentials'}
    if(JSON.parse(localStorage.getItem("token_ttl")) <= ((Date.now()/1000)-1680000000)) {
        axios.post('https://id.twitch.tv/oauth2/token', text)
        .then(res => { 
            localStorage.setItem('token_ttl', JSON.stringify(res.data.expires_in));
            localStorage.setItem('access_token', JSON.stringify(res.data.access_token));
            localStorage.setItem('client_id', JSON.stringify('1hj6jzp6fwobjq4mrfzh9s3300cpk1'));
        })
    }
}