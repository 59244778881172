import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GameDetails } from './Pages/GameDetails';
import { Home } from './Pages/Home';
import { Nav } from './Components/Nav';
import { NoPage } from './Pages/NoPage';
import { SearchResults } from './Pages/SearchResults';
import { UserLists } from './Pages/UserLists';

export default function App() {
    const [userData, setUserData] = useState(localStorage.getItem('userdata') ? JSON.parse(localStorage.getItem('userdata')) : []);
    const [events, setEvents] = useState(localStorage.getItem('events') ? JSON.parse(localStorage.getItem('events')) : []);
    useEffect(() => { localStorage.setItem('events', JSON.stringify(events)) }, [events]);
    useEffect(() => {
      if (userData) {
        const qualityCheck = userData.filter((item)=>item.category >= 0);
        localStorage.setItem('userdata', JSON.stringify(qualityCheck));
      }
    }, [userData]);
    const saveUserData = (e) => setUserData([ ...userData, e])
    const delUserData = (ulGame) => setUserData(userData.filter(e => e.game.name !== ulGame.name))
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Nav userData={userData}
                                            onUserListSave={(e) => saveUserData(e)}
                                            onUserListDel={(e) => delUserData(e)}
                                            events={events}
                                            onEventSave={(e) => setEvents(e)}
                                        />
                                        }>
                    <Route index element={<Home userData={userData} 
                                            onUserDataSave={(e) => setUserData(e)}
                                            events={events}
                                            onEventSave={(e) => setEvents(e)}
                                            onEventDel={(e) => setEvents(e)}
                                            onUserListUpdate={(e) => setUserData(e)}
                                        />
                                        } />
                    <Route path="game-details/:gameId" element={<GameDetails 
                                                                    userData={userData}
                                                                    onUserListSave={(e) => saveUserData(e)}
                                                                    onUserListDel={(e) => delUserData(e)}
                                                                    events={events}
                                                                    onEventSave={(e) => setEvents(e)}
                                                                    onEventDel={(e) => setEvents(e)}
                                                                />
                                                                } />
                    <Route path="search-results/:search" element={<SearchResults 
                                                                    userData={userData}
                                                                    onUserListSave={(e) => saveUserData(e)}
                                                                    onUserListDel={(e) => delUserData(e)}
                                                                    events={events}
                                                                    onEventSave={(e) => setEvents(e)}
                                                                    onEventDel={(e) => setEvents(e)}
                                                                />
                                                                } />
                    <Route path="user-profile" element={<UserLists 
                                                            userData={userData}
                                                            onUserListSave={(e) => saveUserData(e)}
                                                            onUserListDel={(e) => delUserData(e)}
                                                            onUserListUpdate={(e) => setUserData(e)}
                                                            events={events}
                                                            onEventSave={(e) => setEvents(e)}
                                                        />
                                                        } />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);