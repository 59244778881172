import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react"

export const RatingSurvey = ({onClose}) => {
    const [pageNumber,setPageNumber] = useState(0);
    const [ratingCounter,setRatingCounter] = useState([]);
    const [ratingTotal,setRatingTotal] = useState();

    const headers = [
        "Gameplay",
        "Presentation & Immersion",
        "Technical Design",
        "Logistics",
        "Conditional Aspects"
    ]

    const questions = [
        "How intuitive were the controls?",
        "How well-tuned was the gameplay difficulty?",
        "How captivating was the game in maintaining your interest and excitement?",
        "How fulfilling were the rewards for progress or milestones?",
        "How well-paced was the game's progression for this genre?",

        "How engaging was the game's story, writing, and/or lore?",
        "How immersed did you feel within the game's world?",
        "How visually appealing was the art style of the game?",
        "How engaging were the sounds and music in the game?",
        "How well did the game's voiceover complement the dialogue and characters?",

        "How user-friendly were the HUD and in-game menus?",
        "How well-crafted and expansive was the game's content?",
        "How flexible and customizable were the game's settings?",
        "How would you rate the amount of technical issues or glitches you experienced?",
        "How well-designed were the game mechanics?",

        "How satisfied were you with the overall gaming experience?",
        "Did any negative micro-transactions or in-game purchases impact your experience?",
        "How much replay value does this game offer?",
        "Did the game provide sufficient value for its release price?",
        "How would you rate your likelihood to recommend this game to someone?",

        "How meaningful were the choices you made in-game?",
        "How intelligent and lifelike was the game's AI?",
        "How well-implemented was the game's networking functionality?"
    ]

    const addValue = (index,value) => {
        let temp = [...ratingCounter];
        temp[index] = value;
        setRatingCounter(temp);
    }

    const calculateRating = () => {
        const allRatingValues = ratingCounter.filter((rating)=>rating !== -1 && rating !== undefined);
        const personalRating = allRatingValues.reduce((sum,val) => sum + val);
        setRatingTotal(((personalRating/(allRatingValues.length * 5))*10).toFixed(1));
    }

    const close = () => {
        setPageNumber(0);
        setRatingCounter([]);
        setRatingTotal();
        onClose();
    }

    return(
        <div id="updateEventModal" style={{top:"10%",left:"28%",height:"600px",width:"900px",textShadow:"1px 1px 2px black", boxShadow:"2px 2px 20px rgba(100, 180, 250)"}}>
            <button onClick={close} style={{position:"absolute",top:"10px",right:"10px",paddingTop:"0px",paddingBottom:"1px",paddingRight:"5px",paddingLeft:"5px"}} className="btn btn-danger"><i className="fa fa-close"></i></button>
            <h3 style={{textAlign:"center"}}>Rating Survey</h3>
            <h6 style={{textAlign:"center"}}><i>Rating will be averaged over total score.</i></h6>
            <h4>{headers[pageNumber]}</h4>
            <div style={{height:"60%"}}>
                {questions.slice(pageNumber*5,(pageNumber+1)*5).map((question) => {
                    const index = questions.findIndex((q)=>q === question);
                    return(
                        <div key={index}>
                            <div>{question}</div>
                            <RadioGroup row value={ratingCounter[index] ? ratingCounter[index] : ""} onChange={(e)=>addValue(index,Number(e.target.value))}>
                                {pageNumber === headers.length-1 && <FormControlLabel value={-1} control={<Radio />} label="N/A" />}
                                <FormControlLabel value={1} control={<Radio />} label="1 - Horrible" />
                                <FormControlLabel value={1.25} control={<Radio />} label="2 - Below Average" />
                                <FormControlLabel value={2.5} control={<Radio />} label="3 - Average" />
                                <FormControlLabel value={3.75} control={<Radio />} label="4 - Above Average" />
                                <FormControlLabel value={5} control={<Radio />} label="5 - Perfect" />
                            </RadioGroup>
                        </div>
                    )
                })}
            </div>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <button className="btn btn-primary btn-sm" onClick={()=>{setPageNumber(pageNumber-1)}} disabled={pageNumber === 0}>Prev {pageNumber !== 0 && "("+headers[pageNumber-1]+")"}</button>
                <button className="btn btn-primary btn-sm" onClick={()=>{setPageNumber(pageNumber+1)}} disabled={pageNumber === headers.length-1}>Next {pageNumber !== headers.length-1 && "("+headers[pageNumber+1]+")"}</button>
            </div>
            <div style={{textAlign:"center"}}>
                {ratingTotal && <h2>{ratingTotal} / 10</h2>}
                <button className="btn btn-success" onClick={calculateRating} disabled={ratingCounter.findIndex((rating)=>rating === undefined) !== -1 || ratingCounter.length !== questions.length}>Calculate Rating</button>
            </div>
        </div>
    )
}

export default RatingSurvey;