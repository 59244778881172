import React, { useState } from 'react';
import { DeleteEventModal } from './DeleteEventModal';
import { EventListTable } from '../EventList/EventListTable';
import { NewEventModal } from './NewEventModal';
import { useNavigate } from 'react-router-dom';

export const DayEventListModal = ({ eventDate, events, onClose, onDelete, onSave }) => {
    const navigate = useNavigate()
    const [newClick, setNewClick] = useState(false);
    const [delClick, setDelClick] = useState(false);
    const checkNewClick = () => { setNewClick(!newClick) };
    const checkDelClick = () => { setDelClick(!delClick) };
    const [eventName,setEventName] = useState('');
    const eventNames = events.slice().filter(e => e.date === eventDate).map((e,index) =>
                                        <tr key={index}>
                                          <td><div style={{maxWidth:"100px",maxHeight:"100px",overflow:"auto",wordWrap:"break-word"}}>{e.title}</div></td>
                                          {e.game ? <td style={{maxWidth:"200px"}}>
                                              <a onClick={()=>navigate("/game-details/"+e.game.id, {state: e.game.id})} style={{display:"flex",alignItems:"center"}}>
                                                  <img src={e.game.cover.url} style={{borderRadius:"12px"}} />
                                                  <div style={{paddingLeft:"10px"}}>{e.game.name}</div>
                                              </a>
                                          </td> : <td style={{height:"90px"}}>No Game Added</td>}
                                          <td><button className="btn btn-danger" onClick={() => {setEventName(e.title); setDelClick(!delClick);}}>Delete</button></td>
                                        </tr>
                                      )
    return(
    <>
      <div id="newEventModal">
        <div className="boxTitle" style={{fontSize:"30px",fontWeight:"700"}}>{eventDate}</div>
        <div style={{width:"80px",height:"3px",backgroundColor:"cyan",marginLeft:"2px",marginBottom:"10px",marginTop:"5px"}} />
        <button className="btn btn-primary" onClick={checkNewClick}>New Event</button>
        <div style={{maxHeight:"80%",overflow:"auto"}}>
          {eventNames.length > 0 ? <EventListTable eventArray={eventNames} day={true} /> : <div style={{fontSize:"22px",color:"white"}}>No Events Today</div>}
        </div>
        <button onClick={onClose} style={{position:"absolute",top:"10px",right:"10px",paddingTop:"0px",paddingBottom:"1px",paddingRight:"5px",paddingLeft:"5px"}} className="btn btn-danger"><i className="fa fa-close"></i></button>
        {
        newClick &&
            <NewEventModal
            events={events}
            onClose={checkNewClick}
            onSave={onSave}
            />
        }

        {
        delClick &&
            <DeleteEventModal 
            eventText={eventName}
            onClose={checkDelClick}
            onDeleteEvent={onDelete}
            />
        }
      </div>

      <div id="modalBackDrop"></div>
    </>
  );
};

export default DayEventListModal;