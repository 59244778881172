import React, { useState } from 'react';
import { SearchBar } from '../SearchBar';

export const NewEventModal = ({ events, onSave, onClose }) => {
  const [title, setTitle] = useState('');
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [gameDetails, setGameDetails] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const addGame = (details) => setGameDetails(details)
  const clearInput = () => {
      setFilteredData([]);
      setWordEntered("");
  }

  return(
    <>
      <div id="newEventModal">
        <div className="boxTitle" style={{fontSize:"30px"}}>New Event</div>
        <div className="homeSepLine" style={{backgroundColor:"yellow"}} />
        {errorMsg ? <div style={{color:"red"}}>{errorMsg}</div> : <div className="boxTitle" style={{fontSize:"16px"}}>Event Name</div>}
        <input 
          className={error ? 'error' : ''}
          value={title} 
          onChange={e => setTitle(e.target.value)}
          id="eventTitleInput"
          placeholder="Event Title" 
        />
        <div className="boxTitle" style={{fontSize:"16px"}}>(Optional) Add a Game to the Event</div>
        <SearchBar 
          addGame={addGame}
          check={true}
          clearInput={clearInput}
          filteredData={filteredData}
          setFilteredData={e => setFilteredData(e)}
          wordEntered={wordEntered}
          setWordEntered={e => setWordEntered(e)}
          place={true}
        />
        <div style={{marginTop:"25px",textAlign:"center"}}>
          <button className="btn btn-primary"
            onClick={() => {
              if (title) {
                setError(false);
                setErrorMsg(null);
                { 
                  if(events.some(n => n.title === title)) {
                    setError(true);
                    setErrorMsg("Event with this name already exists")
                  } else {
                        onSave({title:title,game:gameDetails})
                  }
                }
              } else {
                setError(true);
                setErrorMsg("Enter a name for the event")
              }
            }} 
            id="saveButton">Save Event</button>
          </div>
          <button onClick={onClose} style={{position:"absolute",top:"10px",right:"10px",paddingTop:"0px",paddingBottom:"1px",paddingRight:"5px",paddingLeft:"5px"}} className="btn btn-danger"><i className="fa fa-close" /></button>
      </div>
      <div id="modalBackDrop" />
    </>
  );
};

export default NewEventModal;