import { useState } from "react";

export const CalendarHeader = ({ onNext, onBack, dateDisplay, onNav }) => {
  const [error, setError] = useState(false);
  const [nav,setNav] = useState('')
  const curr = new Date();

  const clearInput = () => {
    setNav('')
    onNav(0)
    setError(false)
};

  return(
    <div id="header">
      <div id="monthDisplay">{dateDisplay}</div>
      <div>
        {error ? <div style={{fontSize:"11px",color:"red",marginLeft:"-20px",marginBottom:"-7px"}}>Year Must Be Past 1900</div> : null}
        <div className="d-flex" style={{marginTop:"7px"}}>
          <input
            style={{fontSize:"16px",width:"80px",height:"30px"}}
            className={error ? 'error' : ''}
            type="text"
            placeholder="Year..."
            value={nav}
            onChange={(e) => setNav(e.target.value)}
            onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if(e.target.value > 1900) {
                        setError(false)
                        onNav((e.target.value - curr.getFullYear())*12)
                      } else { setError(true) }
                    }
                  }}
          />
        </div>
      </div>
      <div style={{display:"flex",height:"80%",marginTop:"5px"}}>
        <button onClick={onBack} className="btn" style={{color:"#a760e9"}}>{"<<"}</button>
        <button onClick={clearInput} className="btn btn-primary">Today</button>
        <button onClick={onNext} className="btn" style={{color:"#a760e9"}}>{">>"}</button>
      </div>
    </div>
  );
};

export default CalendarHeader;